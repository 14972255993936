import { render, staticRenderFns } from "./PaginationModalLayout.vue?vue&type=template&id=4c3ab54a&scoped=true"
import script from "./PaginationModalLayout.vue?vue&type=script&lang=js"
export * from "./PaginationModalLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3ab54a",
  null
  
)

export default component.exports